/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiTransactionSet214Schema_parse_ = {
    heading?: {
        assignedNumberLXLoop?: Array<{
            electronicFormatIdentificationEFILoop?: Array<{
                electronicFormatIdentificationEFI?: {
                    freeFormMessageText02?: string,
                    securityLevelCode01?: string,
                },
            }>,
            shipmentStatusDetailsAT7Loop?: Array<{
                equipmentOrContainerOwnerAndTypeMS2?: {
                    equipmentNumberCheckDigit04?: number,
                    equipmentDescriptionCode03?: string,
                    standardCarrierAlphaCode01?: string,
                    equipmentNumber02?: string,
                },
                equipmentShipmentOrRealPropertyLocationMS1?: {
                    directionIdentifierCode06?: string,
                    latitudeCode05?: string,
                    longitudeCode04?: string,
                    countryCode03?: string,
                    stateOrProvinceCode02?: string,
                    cityName01?: string,
                },
                shipmentStatusDetailsAT7?: {
                    timeCode07?: 'LT' | 'AD' | 'AS' | 'AT' | 'CD' | 'CS' | 'CT' | 'ED' | 'ES' | 'ET' | 'GM' | 'HD' | 'HS' | 'HT' | 'MD' | 'MS' | 'MT' | 'ND' | 'NS' | 'NT' | 'PD' | 'PS' | 'PT' | 'TD' | 'TS' | 'TT' | 'UT',
                    time06?: string,
                    date05?: string,
                    shipmentStatusOrAppointmentReasonCode04?: 'NA',
                    shipmentAppointmentStatusCode03?: 'AA' | 'AB',
                    shipmentStatusOrAppointmentReasonCode02?: 'AM' | 'AD' | 'AS' | 'NS' | 'D1' | 'D2' | 'AQ' | 'AO' | 'BM' | 'AG' | 'S1' | 'A2' | 'AH' | 'AJ' | 'AN' | 'AX' | 'AY' | 'B1' | 'B2' | 'B5' | 'B8' | 'BB' | 'BD' | 'BF' | 'BH' | 'BP' | 'BQ' | 'BS' | 'C5' | 'HB' | 'P4' | 'RC' | 'T7',
                    shipmentStatusCode01?: 'CD' | 'D1' | 'SD' | 'A9' | 'AF' | 'AG' | 'J1' | 'K1' | 'L1' | 'OO' | 'P1' | 'PR' | 'R1' | 'S1' | 'X1' | 'X2' | 'X3' | 'X4' | 'X5' | 'X6',
                },
            }>,
            assignedNumberLX?: {
                assignedNumber01?: number,
            },
            shipmentWeightPackagingAndQuantityDataAT8?: Array<{
                volumeUnitQualifier06?: 'E',
                ladingQuantity04?: number,
                weightUnitCode02?: 'L',
                weightQualifier01?: 'G' | 'A' | 'A1' | 'B' | 'F',
                volume07?: number,
                weight03?: number,
            }>,
        }>,
        beginningSegmentForTransportationCarrierShipmentStatusMessageB10?: {
            inquiryRequestNumber04?: number,
            standardCarrierAlphaCode03?: string,
            shipmentIdentificationNumber02?: string,
            referenceIdentification01?: string,
        },
        transactionSetTrailerSE?: {
            numberOfIncludedSegments01?: number,
            transactionSetControlNumber02?: string,
        },
        nameN1Loop?: Array<{
            geographicLocationN4?: {
                countryCode04?: string,
                postalCode03?: string,
                stateOrProvinceCode02?: string,
                cityName01?: string,
            },
            addressInformationN3?: Array<{
                addressInformation02?: string,
                addressInformation01?: string,
            }>,
            nameN1?: {
                identificationCode04?: string,
                identificationCodeQualifier03?: '94',
                name02?: string,
                entityIdentifierCode01?: 'CN' | 'SH',
            },
        }>,
        businessInstructionsAndReferenceNumberL11?: Array<{
            description03?: string,
            referenceIdentificationQualifier02?: 'AO' | 'DJ' | 'BM' | 'LO' | 'PO' | 'QN',
            referenceIdentification01?: string,
        }>,
        transactionSetHeaderST?: {
            transactionSetControlNumber02?: string,
            transactionSetIdentifierCode01?: ReturnType_typeofEdiTransactionSet214Schema_parse_.transactionSetIdentifierCode01,
        },
    };
}

export namespace ReturnType_typeofEdiTransactionSet214Schema_parse_ {

    export enum transactionSetIdentifierCode01 {
        _214 = '214',
    }


}
