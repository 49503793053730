import { BillingChargeType, ImportExportType, TransportType } from '@lazr/enums'

export const MAILING_SERVICES = [
    TransportType.ENVELOPE,
    TransportType.PAK,
    TransportType.PARCEL,
]

export const FREIGHT_SERVICES = [
    TransportType.LTL,
    TransportType.FTL,
    TransportType.OTHER,
]

export const LAZR_ONLY_SERVICE_CHARGES =
    [ BillingChargeType.LAZR_COVERAGE, BillingChargeType.LZFEE ]
export const BILLING_CHARGES =
    [ BillingChargeType.FREIGHT, BillingChargeType.ITEM,
        BillingChargeType.WGHT, BillingChargeType.ASWGHT,
        BillingChargeType.DIMWGHT, BillingChargeType.EXPEDITE,
        BillingChargeType.NFC, BillingChargeType.MIN, BillingChargeType.DELC ]

export const RESIDENTIAL_RELATED_ACCESSORIALS = [ 'RESPU', 'RESDEL', 'RSD', 'RSI' ]
export const RESIDENTIAL_DEL_ACCESSORIAL = 'RESDEL'
export const RESIDENTIAL_PU_ACCESSORIAL = 'RESPU'
export const DOCK_DEL_ACCESSORIAL = 'DOCKDEL'
export const DOCK_PU_ACCESSORIAL = 'DOCKPU'
export const LIFTGATE_ACCESSORIALS = [ 'LGPU', 'LGDEL' ]
export const DEFAULT_ACCESSORIALS = [ 'RESPU', 'RESDEL', 'DOCKPU', 'DOCKDEL' ]

export const PUROLATOR_IMPORT_EXPORT_TYPES = [ ImportExportType.PERMANENT, ImportExportType.TEMPORARY, ImportExportType.REPAIR, ImportExportType.RETURN ]

export const GMX_ORGANIZATION_NAME = 'GMX Worldwide Xpress'

export const salesRepresentativeAssignor = [
    'vcollin@lazr.io',
    'ebenoit@lazr.io',
    'mguindon@lazr.io',
    'mbiard@lazr.io',
    'm.gendron@gosynergie.com',
    'mgendron@lazr.io',
    'cgrenier@lazr.io',
    'k.dagenais@gosynergie.com',
    'c.lariviere@gosynergie.com',
]

export const customerCreditStatusAndLimitChanger = [
    'ebenoit@lazr.io',
    'mguindon@lazr.io',
    'm.gendron@gosynergie.com',
    'mgendron@lazr.io',
    'c.lariviere@gosynergie.com',
    'across@lazr.io'
]

export const FREIGHT_COVERAGE_LIMIT = 200000
export const MAILING_COVERAGE_LIMIT = 25000
