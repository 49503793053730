import React, { useState } from 'react'
import View from './View'
import useCarriers from '../../hooks/useCarriers'
import { ApiServiceAction, TransportType } from '@lazr/enums'
import useEnumSelectData from '../../hooks/useEnumSelectData'
import { type PickupDefaultProps } from '../sharedModules/SchedulePickup.module/SchedulePickup'
import { useTimezoneList } from '@/app/ui/components/hooks/useTimezoneList'
import useCountries from '../../hooks/useCountries'
import { processData } from './processData'
import useSchedulePickup from '../hooks/useSchedulePickup'
import useServiceLevel from '../../hooks/useServiceLevel'

const Add: React.FunctionComponent = () => {
    //const transportTypesData2 = filterById()

    const carriers = useCarriers(
        {
            wait: 0.1,
            list: { supportPickup: true },
        },
        ApiServiceAction.LIST,
    ).list

    const [carrierId, setCarrierId] = useState<string| undefined>()
    const [transportType, setTransportType] = useState<TransportType | undefined>()

    const serviceLevel = useServiceLevel(
        {
            list: carrierId ? {
                transportType,
                carrierId: carrierId
            } : undefined
        },
        ApiServiceAction.LIST,
    ).list

    const transportTypesData: PickupDefaultProps['transportTypesData'] = useEnumSelectData({
        id: 'transportTypes',
        filter: [TransportType.PARCEL, TransportType.ENVELOPE, TransportType.PAK],
    })

    const billDutiesToPartyData: PickupDefaultProps['billDutiesToPartyData'] = useEnumSelectData({ id: 'billDutiesToParty' })

    const fedexLocationData: PickupDefaultProps['fedexLocationData'] = useEnumSelectData({ id: 'fedexLocation' })

    const fedexCarrierCodeData: PickupDefaultProps['fedexCarrierCodeData'] = useEnumSelectData({ id: 'fedexCarrierCode' })

    const currencyCodeData: PickupDefaultProps['currencyCodeData'] = useEnumSelectData({ id: 'currencyCode' })

    const [timezoneList] = useTimezoneList()

    const countries = useCountries({ wait: 0.1 }, ApiServiceAction.LIST).list

    const [returnProcessData, setReturnProcessData] = useState<any>()

    const newSchedule = useSchedulePickup(
        {
            newData: returnProcessData,
        },
        ApiServiceAction.NEW,
    ).new


    return (
        <View
            nameID='Address'
            defaultPickup={{
                nameID: 'DefaultPickup',
                processData: { fn: processData, setReturnProcessData: setReturnProcessData },
                carriers: carriers,
                transportTypesData: transportTypesData,
                billDutiesToPartyData: billDutiesToPartyData,
                fedexLocationData: fedexLocationData,
                fedexCarrierCodeData: fedexCarrierCodeData,
                currencyCodeData: currencyCodeData,
                timezoneList: timezoneList ?? [],
                countries: countries,
                setCarrierId: setCarrierId,
                setTransportType: setTransportType,
                serviceLevel: serviceLevel?.data,
                schedulePickupData: newSchedule
            }}
        />
    )
}

export default React.memo(Add, () => true)
