const hostname = window.location.hostname

export enum Environment {
    PRODUCTION = 'PRODUCTION',
    DEVELOPMENT = 'DEVELOPMENT',
    SANDBOX = 'SANDBOX',
}

let env: Environment
let sandbox
let namespace
let httpApiUrl
let webSocketApiUrl

let appUrl

if (RegExp(/^(www\.)?lazr\.io$/i).test(hostname)) {
    env = Environment.PRODUCTION
    httpApiUrl = 'https://api.lazr.io/v1'
    webSocketApiUrl = 'wss://wss.lazr.io/v1'
    appUrl = 'https://lazr.io'
} else if (RegExp(/^dev\.lazr\.io$/i).test(hostname)) {
    env = Environment.DEVELOPMENT
    httpApiUrl = 'https://api.dev.lazr.io/v1'
    webSocketApiUrl = 'wss://wss.dev.lazr.io/v1'
    appUrl = 'https://dev.lazr.io'
} else {
    env = Environment.SANDBOX

    sandbox = process.env.LAZR_SANDBOX || ''
    namespace = process.env.LAZR_NAMESPACE || ''

    if (namespace) {
        httpApiUrl = `https://${namespace}-api.${sandbox}.dev.lazr.io/v1`
        webSocketApiUrl = `wss://${namespace}-wss.${sandbox}.dev.lazr.io/v1`
    } else {
        httpApiUrl = `https://api.${sandbox}.dev.lazr.io/v1`
        webSocketApiUrl = `wss://wss.${sandbox}.dev.lazr.io/v1`
    }
    appUrl = `https://${sandbox}.dev.lazr.io`
}
export {
    env,
    sandbox,
    namespace,
    httpApiUrl,
    webSocketApiUrl,
    appUrl,
}
