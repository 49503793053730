/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiTransactionSet990Schema_parse_ = {
    heading?: {
        remarksK1?: Array<{
            freeFormMessage01?: string,
        }>,
        referenceIdentificationN9?: {
            referenceIdentification02?: string,
            referenceIdentificationQualifier01?: ReturnType_typeofEdiTransactionSet990Schema_parse_.referenceIdentificationQualifier01,
        },
        beginningSegmentForBookingOrPickUpDeliveryB1?: {
            reservationActionCode04?: ReturnType_typeofEdiTransactionSet990Schema_parse_.reservationActionCode04,
            date03?: string,
            shipmentIdentificationNumber02?: string,
            standardCarrierAlphaCode01?: string,
        },
        transactionSetTrailerSE?: {
            numberOfIncludedSegments01?: number,
            transactionSetControlNumber02?: string,
        },
        stopOffDetailsS5Loop?: Array<{
            referenceIdentificationN9?: Array<{
                referenceIdentification02?: string,
                referenceIdentificationQualifier01?: '15',
            }>,
            stopOffDetailsS5?: {
                stopReasonCode02?: 'CN' | 'DT' | 'PU' | 'CU' | 'LD' | 'PL' | 'UL' | 'CL',
                stopSequenceNumber01?: number,
            },
        }>,
        equipmentDetailsN7?: {
            equipmentNumber02?: string,
            equipmentInitial01?: string,
        },
        transactionSetHeaderST?: {
            transactionSetControlNumber02?: string,
            transactionSetIdentifierCode01?: ReturnType_typeofEdiTransactionSet990Schema_parse_.transactionSetIdentifierCode01,
        },
    };
}

export namespace ReturnType_typeofEdiTransactionSet990Schema_parse_ {

    export enum referenceIdentificationQualifier01 {
        _15 = '15',
        CN = 'CN',
    }

    export enum reservationActionCode04 {
        A = 'A',
        D = 'D',
    }

    export enum transactionSetIdentifierCode01 {
        _990 = '990',
    }


}
