/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['New Version Available']: 'Nouvelle version disponible',
    ['Reload Lazr at your convenience for the latest version.']: 'Rechargez Lazr à votre convenance pour la dernière version.',
    ['Reload']: 'Recharger',
    Attention:'Attention',
    message: "Malheureusement, en raison de la grève ferroviaire en cours à travers le Canada, les tarifs de transport de marchandises à destination et en provenance de l'Alberta, de la Colombie-Britannique, du Manitoba et de la Saskatchewan sont sujets à changement sans préavis. La situation évolue rapidement et hors de notre contrôle. Soyez assurés que l’équipe de Lazr surveille la situation de près et vous tiendra au courant des développements."
}))()
