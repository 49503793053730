import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { Button, Wrapper, BigAvatar } from './SignIn.styled'
import { logger } from '../../../logger'
import * as EmailValidator from 'email-validator'
import { MIN_PASSWORD_LENGTH } from '../../redux/constants'
import { SPINNER_ICON } from '../../components/Modal'
import PasswordField from '../../components/PasswordField'
import { FormControl, TextField, Theme, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppTheme } from '../../definitions/Theme'
import Timer from '../../components/Timer'
import { isSignedIn, UserApiService } from '../../../service/ApiService'
import * as ApiService from '../../../service/ApiService'
import { User } from '../../../model'
import { dashboardRoute,  newMarketplaceShipmentInfoRoute, newOrderListRoute, orderListRoute } from '../../routes'
import { getUser as reduxGetUser } from '../../redux/selectors'
import { setUserIsSignedIn as reduxSetUserIsSignedIn } from '../../redux/actions/UserActions'

import { getUserOrganization as reduxGetUserOrganization } from '@/app/ui/redux/selectors/UserOrganizationSelectors'

const secondsToResendVerificationEmail = 60

interface LocalStorageUserInfo {
    email: string | null
    firstName: string | null
    pictureUrl: string | null
}

interface LocalStorageHasOrder {
    hasOrder: string | null
}

const localStorageHelper = {
    setUserInfo: (user: User): void => {
        localStorage.setItem('userEmail', user.email)
        localStorage.setItem('userFirstName', user.firstName ?? '')
        localStorage.setItem('userPictureUrl', user.pictureUrl ?? '')
    },
    getUserInfo: (): LocalStorageUserInfo => ({
        email: localStorage.getItem('userEmail'),
        firstName: localStorage.getItem('userFirstName'),
        pictureUrl: localStorage.getItem('userPictureUrl'),
    }),
    setHasOrder: (value: string): void => {
        localStorage.setItem('hasOrder', value)
    },
    getHasOrder: (): LocalStorageHasOrder => ({
        hasOrder: localStorage.getItem('hasOrder'),
    }),
}

const SignIn: React.FunctionComponent<Props> = ({ isModal = false, setIsResetPassword }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const userInfoEmail = localStorageHelper.getUserInfo().email

    const [email, setEmail] = useState<string>(userInfoEmail ?? '')
    const [password, setPassword] = useState<string>('')
    const [emailError, setEmailError] = useState<boolean>(false)
    const [passwordError, setPasswordError] = useState<boolean>(false)
    const [showResendButton, setShowResendButton] = useState<boolean>(false)
    const [processingResendButton, setProcessingResendButton] = useState<boolean>(false)
    const [emailErrorText, setEmailErrorText] = useState<string>('')
    const [passwordErrorText, setPasswordErrorText] = useState<string>('')
    const [isSignInInProgress, setIsSignInInProgress] = useState<boolean>(false)
    const [userInfo] = useState<LocalStorageUserInfo>(localStorageHelper.getUserInfo())

    const theme = useTheme<AppTheme & Theme>()

    const isFormValid = (): boolean => {
        let isValid = true

        if (!email) {
            setEmailError(true)
            setEmailErrorText('Email cannot be empty')
            isValid = false
        } else if (!EmailValidator.validate(email)) {
            setEmailError(true)
            setEmailErrorText('Email is not a valid format')
            isValid = false
        } else {
            setEmailError(false)
            setEmailErrorText('')
        }

        if (!password) {
            setPasswordError(true)
            setPasswordErrorText('Password cannot be empty')
            isValid = false
        } else if (password.length < MIN_PASSWORD_LENGTH) {
            setPasswordError(true)
            setPasswordErrorText(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`)
            isValid = false
        } else {
            setPasswordError(false)
            setPasswordErrorText('')
        }

        return isValid
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setter: (val: string) => void): void => {
        setter(event.target.value)
    }

    const handleResetPasswordClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault()
        if (!isModal) {
            history.push('/reset-password')
        } else if (setIsResetPassword) {
            setIsResetPassword(true)
        }
    }

    const handleSignIn = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()

        if (isSignInInProgress) {
            return
        }

        if (!isFormValid()) {
            return
        }

        try {
            setIsSignInInProgress(true)

            const token = await UserApiService.authenticate(email, password)

            ApiService.signIn(token)

            let user = null
            if (isSignedIn()) {
                user = await UserApiService.getCurrent()
            }

            if (user) {
                ApiService.switchedOrganizationRequests(user.organizationId)
                localStorageHelper.setUserInfo(user)

                localStorageHelper.setUserInfo(user)

                const hasOrder = localStorageHelper.getHasOrder().hasOrder
                if (!hasOrder) {
                    const resultList = await ApiService.OrderApiService.list(
                        {
                            page: 1,
                            resultPerPage: 1,
                        },
                        {},
                    )
                    if (resultList.total > 0) {
                        localStorageHelper.setHasOrder(resultList.total.toString())
                    }
                }
            }

            if (isModal) {
                dispatch(reduxSetUserIsSignedIn(true))
            } else {
                // TODO: change to new order and new marketplace we switch
                const hasOrder = localStorageHelper.getHasOrder().hasOrder
                if(hasOrder){
                    window.location.href = newOrderListRoute.path
                }else{
                    window.location.href = newMarketplaceShipmentInfoRoute.path
                }
            }
        } catch (error: any) {
            logger.error('error signing in:', error)

            if (error.message === 'UserNotConfirmedException') {
                setPasswordErrorText('This email has not been verified')
            } else if (error.message === 'UserNotActivatedException') {
                setPasswordErrorText('You have not yet been approved by your administrator')
            } else {
                setPasswordErrorText('This email / password combination is invalid')
            }
            setPasswordError(true)
            setShowResendButton(error.message === 'UserNotConfirmedException')
            setIsSignInInProgress(false)
        }
    }

    if (useSelector(reduxGetUser) && !isModal) {
        return <Redirect to={dashboardRoute.path} />
    }

    return (
        <Wrapper $isModal={isModal}>
            {(userInfo?.firstName || userInfo?.pictureUrl) && (
                <BigAvatar alt={userInfo.firstName ?? ''} src={userInfo.pictureUrl ?? ''}>
                    <FontAwesomeIcon icon={['fas', 'user-circle']} />
                </BigAvatar>
            )}

            <Typography component='h1' variant='h5' align='center' gutterBottom>
                Welcome{userInfo.firstName ? ` back to Lazr, ${userInfo.firstName}` : ' to Lazr'}!
            </Typography>
            <Typography component='h2' variant='subtitle2' align='center'>
                Sign in to your account to continue
            </Typography>
            <form onSubmit={handleSignIn}>
                <FormControl margin='normal' required fullWidth>
                    <TextField
                        name='email'
                        label='Email'
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            handleInputChange(e, setEmail)
                        }}
                        autoComplete='email'
                        error={emailError}
                        helperText={emailErrorText}
                        autoFocus
                    />
                </FormControl>
                <FormControl margin='normal' required fullWidth>
                    <PasswordField
                        name='password'
                        label='Password'
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            handleInputChange(e, setPassword)
                        }}
                        autoComplete='current-password'
                        error={passwordError}
                        helperText={passwordErrorText}
                    />
                </FormControl>
                <Button
                    fullWidth
                    type='submit'
                    size='medium'
                    variant='contained'
                    color='primary'
                    mt={theme.spacing(2)}
                    disabled={isSignInInProgress}
                    endIcon={isSignInInProgress && <FontAwesomeIcon icon={SPINNER_ICON} pulse />}
                >
                    {isSignInInProgress ? 'Signing in...' : 'Sign in'}
                </Button>
                {/*
                <Button
                    // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
                    component={Link}
                    to="/register"
                    fullWidth
                    color="primary"
                    size="small"
                    mt={theme.spacing(2)}
                >
                    Register a new account
                </Button>
                */}
                <Button onClick={handleResetPasswordClick} fullWidth color='primary' size='small' mt={8}>
                    Forgot password?
                </Button>
                {showResendButton && (
                    <Button
                        fullWidth
                        color='primary'
                        size='small'
                        mt={2}
                        disabled={processingResendButton}
                        onClick={async (): Promise<void> => {
                            setProcessingResendButton(true)
                            window.setTimeout(() => setProcessingResendButton(false), secondsToResendVerificationEmail * 1000)
                            await UserApiService.resendConfirmationCode(email)
                        }}
                    >
                        {processingResendButton ? (
                            <span>
                                Resend verification email in <Timer initialSeconds={secondsToResendVerificationEmail} />
                            </span>
                        ) : (
                            'Resend verification email'
                        )}
                    </Button>
                )}
            </form>
        </Wrapper>
    )
}

export interface Props {
    isModal?: boolean
    setIsResetPassword?: (isResetPassword: boolean) => void
}

export default SignIn
