/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiTransactionSet204Schema_parse_ = {
    summary?: {
        transactionSetTrailerSE?: {
            numberOfIncludedSegments01?: number,
            transactionSetControlNumber02?: string,
        },
        totalWeightAndChargesL3?: {
            rateValueQualifier15?: string,
            declaredValue14?: number,
            weightUnitCode12?: ReturnType_typeofEdiTransactionSet204Schema_parse_.weightUnitCode12,
            ladingQuantity11?: number,
            volumeUnitQualifier10?: ReturnType_typeofEdiTransactionSet204Schema_parse_.volumeUnitQualifier10,
            charge05?: number,
            rateValueQualifier04?: ReturnType_typeofEdiTransactionSet204Schema_parse_.rateValueQualifier04,
            weightQualifier02?: ReturnType_typeofEdiTransactionSet204Schema_parse_.weightQualifier02,
            weight01?: number,
            volume09?: number,
        },
    };
    detail?: {
        stopOffDetailsS5Loop?: Array<{
            orderIdentificationDetailOIDLoop?: Array<{
                descriptionMarksAndNumbersL5Loop?: Array<{
                    contactG61Loop?: Array<{
                        contactG61?: {
                            communicationNumber04?: string,
                            communicationNumberQualifier03?: 'EM' | 'TE',
                            contactFunctionCode01?: string,
                            name02?: string,
                        },
                        businessInstructionsAndReferenceNumberL11?: Array<{
                            description03?: string,
                            referenceIdentificationQualifier02?: '1O' | 'EU' | 'SI' | 'IX' | 'ACD' | 'AO' | 'DJ' | 'EO' | 'P8',
                            referenceIdentification01?: string,
                        }>,
                    }>,
                    shipmentWeightPackagingAndQuantityDataAT8?: {
                        volumeUnitQualifier06?: 'E',
                        ladingQuantity05?: number,
                        ladingQuantity04?: number,
                        weightUnitCode02?: 'L',
                        weightQualifier01?: 'G',
                        volume07?: number,
                        weight03?: number,
                    },
                    descriptionMarksAndNumbersL5?: {
                        commodityCodeQualifier04?: 'N',
                        commodityCode03?: string,
                        ladingDescription02?: string,
                    },
                }>,
                ladingDetailLAD?: Array<{
                    ladingDescription13?: string,
                    ladingQuantity02?: number,
                    packagingFormCode01?: 'BOX' | 'CNT' | 'PCS',
                }>,
                orderIdentificationDetailOID?: {
                    volume09?: number,
                    weight07?: number,
                    weightUnitCode06?: string,
                    quantity05?: number,
                    unitOrBasisForMeasurementCode04?: string,
                    purchaseOrderNumber02?: string,
                    volumeUnitQualifier08?: string,
                    referenceIdentification01?: string,
                },
            }>,
            palletInformationPLD?: {
                quantityOfPalletsShipped01?: number,
            },
            stopOffDetailsS5?: {
                volumeUnitQualifier08?: 'E',
                volume07?: number,
                unitOrBasisForMeasurementCode06?: 'PL' | 'MX' | 'PC',
                numberOfUnitsShipped05?: number,
                weightUnitCode04?: 'K' | 'L',
                weight03?: number,
                stopReasonCode02?: 'PU' | 'CU' | 'LD' | 'PL' | 'UL',
                stopSequenceNumber01?: number,
            },
            nameN1Loop?: Array<{
                additionalNameInformationN2?: {
                    name02?: string,
                    name01?: string,
                },
                contactG61?: Array<{
                    contactInquiryReference05?: string,
                    communicationNumber04?: string,
                    communicationNumberQualifier03?: 'EX' | 'EM' | 'FX' | 'TE',
                    contactFunctionCode01?: string,
                    name02?: string,
                }>,
                geographicLocationN4?: {
                    countryCode04?: string,
                    postalCode03?: string,
                    stateOrProvinceCode02?: string,
                    cityName01?: string,
                },
                addressInformationN3?: Array<{
                    addressInformation02?: string,
                    addressInformation01?: string,
                }>,
                nameN1?: {
                    identificationCode04?: string,
                    identificationCodeQualifier03?: '93',
                    name02?: string,
                    entityIdentifierCode01?: 'SF' | 'ST',
                },
            }>,
            noteSpecialInstructionNTE?: Array<{
                description02?: string,
                noteReferenceCode01?: string,
            }>,
            dateTimeG62?: Array<{
                timeCode05?: 'LT' | 'AT' | 'CD' | 'CT' | 'ED' | 'ET' | 'MD' | 'MT' | 'PT' | 'TD',
                time04?: string,
                timeQualifier03?: '2' | '3' | 'U' | 'X' | 'Y' | 'Z',
                date02?: string,
                dateQualifier01?: '69' | '70' | '96' | '97' | '98' | '99',
            }>,
            businessInstructionsAndReferenceNumberL11?: Array<{
                description03?: string,
                referenceIdentificationQualifier02?: string,
                referenceIdentification01?: string,
            }>,
        }>,
    };
    heading?: {
        equipmentDetailsN7Loop?: Array<{
            equipmentDetailsN7?: {
                width21?: number,
                height20?: number,
                equipmentLength15?: number,
                temperatureControl13?: string,
                equipmentDescriptionCode11?: '20' | '25' | '26' | '40' | '50' | '53' | '75' | 'AM' | 'CN' | 'FR' | 'RD' | '2B' | '2F' | '3M' | '4B' | '4F' | '4M' | '4Q' | '4T' | '4Y' | '4Z' | '5F' | '5M' | '5Q' | '5T' | '5Y' | '5Z' | 'AI' | 'AQ' | 'BG' | 'C4' | 'CB' | 'CG' | 'CI' | 'CJ' | 'CM' | 'CQ' | 'CW' | 'CX' | 'CZ' | 'DD' | 'DT' | 'FF' | 'FT' | 'HV' | 'IP' | 'IR' | 'IV' | 'IX' | 'LF' | 'LU' | 'OT' | 'OV' | 'PU' | 'Q4' | 'RA' | 'RR' | 'RS',
                equipmentNumber02?: string,
                equipmentInitial01?: string,
            },
        }>,
        nameN1Loop?: Array<{
            contactG61?: Array<{
                communicationNumber04?: string,
                communicationNumberQualifier03?: 'EM' | 'FX' | 'TE',
                contactFunctionCode01?: string,
                name02?: string,
            }>,
            geographicLocationN4?: {
                countryCode04?: string,
                postalCode03?: string,
                stateOrProvinceCode02?: string,
                cityName01?: string,
            },
            addressInformationN3?: Array<{
                addressInformation02?: string,
                addressInformation01?: string,
            }>,
            nameN1?: {
                identificationCode04?: string,
                identificationCodeQualifier03?: '93',
                name02?: string,
                entityIdentifierCode01?: 'BT',
            },
        }>,
        noteSpecialInstructionNTE?: Array<{
            description02?: string,
            noteReferenceCode01?: string,
        }>,
        hazardousCertificationLH6?: Array<{
            hazardousCertificationDeclaration04?: string,
            hazardousCertificationDeclaration03?: string,
            hazardousCertificationCode02?: '1',
            name01?: string,
        }>,
        billOfLadingHandlingRequirementsAT5?: Array<{
            specialHandlingDescription03?: string,
            specialServicesCode02?: 'EX' | 'D1' | 'D2' | 'RD' | 'SD',
            specialHandlingCode01?: 'EE' | 'EX' | 'FR' | 'HQT' | 'HZD' | 'IMS' | 'KMD' | 'LTT' | 'MRF' | 'OTC' | 'PUP' | 'TDC' | 'TSC',
        }>,
        dateTimeG62?: {
            timeCode05?: ReturnType_typeofEdiTransactionSet204Schema_parse_.timeCode05,
            time04?: string,
            timeQualifier03?: ReturnType_typeofEdiTransactionSet204Schema_parse_.timeQualifier03,
            date02?: string,
            dateQualifier01?: ReturnType_typeofEdiTransactionSet204Schema_parse_.dateQualifier01,
        },
        businessInstructionsAndReferenceNumberL11?: Array<{
            referenceIdentificationQualifier02?: '1O' | 'CN' | 'DI' | 'DU' | 'EQ' | 'EU' | 'F9' | 'MB' | 'OQ' | 'SCA' | 'SI',
            referenceIdentification01?: string,
        }>,
        setPurposeB2A?: {
            applicationType02?: ReturnType_typeofEdiTransactionSet204Schema_parse_.applicationType02,
            transactionSetPurposeCode01?: ReturnType_typeofEdiTransactionSet204Schema_parse_.transactionSetPurposeCode01,
        },
        beginningSegmentForShipmentInformationTransactionB2?: {
            shipmentQualifier07?: ReturnType_typeofEdiTransactionSet204Schema_parse_.shipmentQualifier07,
            shipmentMethodOfPayment06?: ReturnType_typeofEdiTransactionSet204Schema_parse_.shipmentMethodOfPayment06,
            weightUnitCode05?: ReturnType_typeofEdiTransactionSet204Schema_parse_.weightUnitCode05,
            shipmentIdentificationNumber04?: string,
            standardCarrierAlphaCode02?: string,
        },
        transactionSetHeaderST?: {
            transactionSetControlNumber02?: string,
            transactionSetIdentifierCode01?: ReturnType_typeofEdiTransactionSet204Schema_parse_.transactionSetIdentifierCode01,
        },
    };
}

export namespace ReturnType_typeofEdiTransactionSet204Schema_parse_ {

    export enum weightUnitCode12 {
        K = 'K',
        L = 'L',
    }

    export enum volumeUnitQualifier10 {
        E = 'E',
    }

    export enum rateValueQualifier04 {
        MB = 'MB',
        FR = 'FR',
        PL = 'PL',
        HM = 'HM',
        MA = 'MA',
        PM = 'PM',
    }

    export enum weightQualifier02 {
        G = 'G',
    }

    export enum timeCode05 {
        LT = 'LT',
        AD = 'AD',
        AS = 'AS',
        AT = 'AT',
        CD = 'CD',
        CS = 'CS',
        CT = 'CT',
        ED = 'ED',
        ES = 'ES',
        ET = 'ET',
        GM = 'GM',
        HD = 'HD',
        HS = 'HS',
        HT = 'HT',
        MD = 'MD',
        MS = 'MS',
        MT = 'MT',
        ND = 'ND',
        NS = 'NS',
        NT = 'NT',
        PD = 'PD',
        PS = 'PS',
        PT = 'PT',
        TD = 'TD',
        TS = 'TS',
        TT = 'TT',
        UT = 'UT',
    }

    export enum timeQualifier03 {
        _1 = '1',
    }

    export enum dateQualifier01 {
        _64 = '64',
    }

    export enum applicationType02 {
        LT = 'LT',
    }

    export enum transactionSetPurposeCode01 {
        _49 = '49',
        _00 = '00',
        _01 = '01',
        _04 = '04',
        _05 = '05',
    }

    export enum shipmentQualifier07 {
        L = 'L',
    }

    export enum shipmentMethodOfPayment06 {
        CC = 'CC',
        PP = 'PP',
        TP = 'TP',
    }

    export enum weightUnitCode05 {
        K = 'K',
        L = 'L',
    }

    export enum transactionSetIdentifierCode01 {
        _204 = '204',
    }


}
